<template>
  <div
    class="primary"
    style="overflow-x: hidden"
  >
    <vs-row
      vs-justify="start"
      :style="{ background: `${cor1} ${bgInterval} fixed`, backgroundSize: 'cover' }"
    >
      <vs-col
        type="flex"
        vs-justify="start"
        vs-align="center"
        vs-lg="12"
        vs-xs="12"
        class="px-5 py-5"
        style="min-height: calc(100vh - 186px);"
      >
        <p
          v-if="msgIntervalo"
          class="call text-white m-5 p-5"
        >
          {{ msgIntervalo }}
        </p>

        <h3
          v-if="menuSelectItem"
          class="text-white mb-3 ml-1"
          style="font-weight: 400;"
        >
          {{ menuSelectItem.categoria ? menuSelectItem.categoria : menuSelectItem.nomeSubCat }}
        </h3>
        <!-- Buttons -->
        <section
          v-if="menuSelectItem && menuSelectItem.SubCategorias"
          class="d-flex flex-wrap"
        >
          <div
            v-for="item in menuSelectItem.SubCategorias"
            :key="item.id"
          >
            <div
              v-if="checkItemAccess(item.accessValue)"
              style="max-width: 225px;min-width:225px"
            >
              <div class="row ml-1 d-flex justify-content-start align-items-center mt-2">
                <div
                  class="iconsFixInterval col-sm-12 col-md-12 shadow-sm d-flex justify-content-center align-items-center"
                  style="cursor:pointer; background-color: rgba(255,255,255,.2); border: 1px white solid;"
                  :class="item.nomeSubCat === 'FILTROS' || item.nomeSubCat === 'SINGLE FAN VIEW' ? 'c-filters' : ''"
                  @click="clickItem(item, item.id)"
                >
                  <span v-if="item.icon.includes('fa')">
                    <i
                      :class="item.icon"
                      style="font-size: 48px"
                    />
                  </span>
                  <span v-else>
                    <inline-svg
                      :src="item.icon.split(' ')[0]"
                      width="58"
                      height="58"
                      :fill="item.icon.split(' ')[1]"
                    />
                  </span>
                </div>
                <div
                  class="col-sm-12 col-md-11 text-center m-1 mt-1"
                  style="max-width: 141px"
                >
                  <label
                    class="font-weight-bold"
                    :style="{color: 'white'}"
                  >{{ item.categoria ? item.categoria.replaceAll("&CLUBE&", client).replaceAll("&GENDER&", gender == 1 ? 'A ' : 'O ') : item.nomeSubCat.replaceAll('&CLUBE&', client).replaceAll("&GENDER&", gender == 1 ? 'A ' : 'O ') }}</label>
                </div>
              </div>
            </div>
          </div>
        </section>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js';
import inlineSvg from 'vue-inline-svg';
import axios from 'axios';

export default {
  name: 'Interval',
  components: { inlineSvg },
  props: {
    showOperationsMenu: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    cor1: themeConfig.themeColor,
    msgIntervalo: '',
  }),
  computed: {
    bgInterval() {
      return this.$store.state.configBox.bgInterval
    },
    menuSelectItem() {
      return this.$store.state.menuSelectItem[this.$store.state.menuSelectItem.length-1]
    },
    menuSidebar() {
      return this.$store.state.menuSidebar[0];
    },
    operationsHub() {
      const menu = this.menuSidebar;

      const found = menu.find((item) => {
        return item.categoria === 'HUB DE OPERAÇÕES';
      });

      return [found];
    },
    token() {
      const token = this.$store.state.accessToken.split('&');

      return token[0].replace('tk=', '');
    },
    client() {
      let nameNouns = this.$store.state.configBox.nouns.client

      if(nameNouns == null || nameNouns == undefined || nameNouns == '')
        return 'CLUBE';

      return nameNouns.toUpperCase();
    },
    gender() {
      return this.$store.state.configBox.nouns.gender
    },
  },
  created() {
    this.cor1 = this.$store.state.configBox.cor1;
  },
  mounted() {
    if (this.showOperationsMenu) {
      this.$store.state.menuSelectItem = this.operationsHub;
    }
  },
  methods: {
    checkItemAccess(access) {
      if (!access) return true
      else {
        let resp = false
        access.forEach(i => {
          if (this.$store.state.loginData.accessValues.includes(i)) {
            resp = true
          }
        })
        return resp
      }
    },
    obterSubCategoriaPorId(id) {
      for (let i = 0; i < this.menuSelectItem.SubCategorias.length; i++) {
        if (this.menuSelectItem.SubCategorias[i].id === id) {
          return this.menuSelectItem.SubCategorias[i].nomeSubCat;
        }
      }
      return null
    },
    clickItem(item,index) {
      let clicado = '';

      if (!item.SubCategorias) {
        this.$store.dispatch('push_menuSidebar', this.menuSelectItem.SubCategorias)
        clicado = this.obterSubCategoriaPorId(index)
      } else if (item.urlRedirect === '/interval') {
        this.$store.dispatch('push_menuSidebar', this.menuSelectItem.SubCategorias)
        clicado = this.obterSubCategoriaPorId(index)
      } else {
        this.$store.dispatch('push_menuSidebar', item.SubCategorias)
        clicado = item.SubCategorias
      }
      this.$store.dispatch('push_menuSelectItem', item)

      const baseDomain = process.env.VUE_APP_BASEDOMAIN;
      const baseEnv = process.env.VUE_APP_BASEENV;
      const baseUrl = `${baseDomain}/${baseEnv}`;

      let url = `${baseUrl}/NavigationLog/create/${clicado}`

      const headers = { headers: { Authorization: `Bearer ${this.token}` } };

      axios
        .post(url, null, headers)
        .then();

      this.$router.push({path: item.urlRedirect})
    },
  }
}
</script>
<style scoped>
.ps {
  height: 100px;
}
.call {
  font-weight: 600;
  font-size: 2.4rem;
}
.subCall {
  font-weight: 300;
  font-size: 1.5rem;
}
.iconsFixInterval {
  height: 150px;
  border-radius: 18px;
  min-width: 150px;
  max-width: 150px;
}
.iconsFixInterval:hover {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
  background-color: rgba(255,255,255,.05) !important;
}
.c-filters {
  background-color: rgba(0, 0, 0,.8) !important;
}
.c-filters:hover {
    background-color: rgba(97, 97, 93,.2) !important;
}
</style>
